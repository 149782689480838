import { Tab, Tabs } from "react-bootstrap"
import "./PDPWidget.scss"
import Questions from "./Questions/Questions"
import Reviews from "./Reviews/Reviews"
import PopupCard from "../PopupCard/PopupCard"
import { useEffect, useState } from "react"
import { useSearchParams } from "react-router-dom"
import SiderbarWidget from "./SiderbarWidget"
import ApiManager from "../../classes/ApiManager"

const PDPWidget = () => {
  const apiManager = ApiManager.getInstance()
  const [searchParams] = useSearchParams()

  let xToken = searchParams.get("store")

  apiManager._setXToken(xToken)

  const [brandSettingsData, setBrandSettingsData] = useState({})

  useEffect(() => {
    apiManager
      .get("getBrandSettings", null)
      .then((response) => {
        console.log("===>getBrandSettingsPDPWidget", response.result)
        setBrandSettingsData(response.result)
        console.log(brandSettingsData, "")
      })
      .catch((error) => {
        console.error("Error:In getBrandSettings", error)
      })
  }, [])

  // settings in customizer
  let happyCustomer = searchParams.get("happyCustomer")
  let fontFamily = searchParams.get("fontFamily")
  let categories = searchParams.get("categories")?.split(",")
  let reviewText = searchParams.get("reviewText")
  let questionText = searchParams.get("questionText")
  let headingSize = searchParams.get("headingSize") + "px"
  let limit = searchParams.get("limit")
  let sort = searchParams.get("sort")
  let ugc = searchParams.get("ugc")
  let showFilter = searchParams.get("showFilter")
  let reviewCta = searchParams.get("reviewCta")
  let questionCta = searchParams.get("questionCta")
  let ctaText = searchParams.get("ctaText")
  let ctaQuestion = searchParams.get("ctaQuestion")
  let ctaFontSize = searchParams.get("ctaFontSize") + "px"
  // let bgColor = brandSettingsData?.generalAppearance?.reviewText
  let bgColor =
    brandSettingsData?.generalAppearance?.cardFillColor ?? "transparent"
  let primaryColor =
    brandSettingsData?.generalAppearance?.brandColor ?? "#000000"
  let secondaryColor =
    `${brandSettingsData?.generalAppearance?.brandColor}80` ?? "#333333"
  // let badgeColor = searchParams.get("badgeColor");
  // let ctaFillColor = searchParams.get("ctaFillColor");
  // let ctaTextColor = searchParams.get("ctaTextColor");
  // let ctaBorderColor = searchParams.get("ctaBorderColor");
  // let reviewCardColor = searchParams.get("reviewCardColor");
  let reviewCardBorderColor =
    brandSettingsData?.generalAppearance?.cardBorderColor ?? "#e7e7e7"
  let cardFillColor =
    brandSettingsData?.generalAppearance?.cardFillColor ?? "transparent"
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const [activeTab, setActiveTab] = useState("")

  console.log("categories", categories)
  const handleToggle = (tab) => {
    setActiveTab(tab)
    setSidebarOpen(!sidebarOpen)
  }

  const sendMaxHeightToParent = () => {
    const maxHeight = document.body.scrollHeight
    console.log("inreact", maxHeight)
    window.parent.postMessage({ contentHeighthhehe: maxHeight }, "*")
  }

  useEffect(() => {
    window.addEventListener("load", sendMaxHeightToParent)

    return () => {
      window.removeEventListener("load", sendMaxHeightToParent)
    }
  }, [])

  console.log(ctaFontSize, "askjdhakjsdhkash")
  return (
    <>
      {/* <SiderbarWidget /> */}
      <div
        className="pdp-wrapper"
        style={{
          "--primary-color": primaryColor ?? "#000",
          // "--sidebar-content": primaryColor ?? "#000",

          paddingTop: happyCustomer ? "10px" : "45px",
          "--background-color": cardFillColor,
          "--family-font":
            xToken === "7ddac4-3.myshopify.com" ? "Visby Semibold" : "",
        }}
        onLoad={() => setTimeout(sendMaxHeightToParent, 300)}
      >
        {categories?.includes("reviews") &&
        categories?.includes("questions") ? (
          <Tabs
            defaultActiveKey={
              categories?.includes("reviews") ? "reviews" : "questions"
            }
            id="uncontrolled-tab-example"
            className="pdp-tabs"
            onSelect={() => setTimeout(sendMaxHeightToParent, 200)}
            style={{
              fontSize: headingSize,
              color: primaryColor,
              borderColor: primaryColor,
            }}
          >
            <Tab eventKey="reviews" title={reviewText ?? "Reviews"}>
              <Reviews
                primaryColor={primaryColor}
                secondaryColor={secondaryColor}
                style={{ fontColor: secondaryColor, borderColor: primaryColor }}
                sendMaxHeightToParent={sendMaxHeightToParent}
                brandSettingsData={brandSettingsData}
              />
            </Tab>

            <Tab eventKey="questions" title={questionText ?? "Questions"}>
              <Questions
                primaryColor={primaryColor}
                secondaryColor={secondaryColor}
                sendMaxHeightToParent={sendMaxHeightToParent}
                brandSettingsData={brandSettingsData}
              />
            </Tab>
          </Tabs>
        ) : categories?.includes("reviews") ? (
          <Reviews
            primaryColor={primaryColor}
            secondaryColor={secondaryColor}
            style={{ fontColor: secondaryColor, borderColor: primaryColor }}
            sendMaxHeightToParent={sendMaxHeightToParent}
            reviewCardBorderColor={reviewCardBorderColor}
            brandSettingsData={brandSettingsData}
          />
        ) : (
          <Questions
            primaryColor={primaryColor}
            secondaryColor={secondaryColor}
            sendMaxHeightToParent={sendMaxHeightToParent}
            brandSettingsData={brandSettingsData}
          />
        )}

        {/* <PopupCard /> */}
      </div>
    </>
  )
}

export default PDPWidget
