import "./PDPSelect.scss"

const PDPSelect = ({
  defaultValue,
  options,
  onChange,
  primaryColor,
  secondaryColor,
  value,
}) => {
  return (
    <select
      className="pdp-select"
      onChange={(e) => {
        onChange && onChange(e.target.value)
      }}
      value={value}
      style={{
        // borderColor: secondaryColor,
        color: primaryColor
      }}
    >
      <option disabled selected>
        {defaultValue}
      </option>
      {options.map((item) => (
        <option key={item.label} value={item.value}>
          {item.label}
        </option>
      ))}
    </select>
  )
}

export default PDPSelect
