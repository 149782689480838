import { Tab, Tabs } from "react-bootstrap"
import "./PDPWidget.scss"
import Questions from "./Questions/Questions"
import Reviews from "./Reviews/Reviews"
import PopupCard from "../PopupCard/PopupCard"
import { useEffect, useState } from "react"
import { useSearchParams } from "react-router-dom"
import ApiManager from "../../classes/ApiManager"

export default function SidebarWidget() {
  const apiManager = ApiManager.getInstance()
  const [searchParams] = useSearchParams()

  let xToken = searchParams.get("store")

  apiManager._setXToken(xToken)

  const [brandSettingsData, setBrandSettingsData] = useState({})

  useEffect(() => {
    apiManager
      .get("getBrandSettings", null)
      .then((response) => {
        console.log("===>getBrandSettingsPDPWidget", response.result)
        setBrandSettingsData(response.result)
        console.log(brandSettingsData, "")
      })
      .catch((error) => {
        console.error("Error:In getBrandSettings", error)
      })
  }, [])
  // let categories = searchParams.get("categories")?.split(",")
  // let reviewText = searchParams.get("reviewText")
  // let questionText = searchParams.get("questionText")
  // let headingSize = searchParams.get("headingSize")

  let sidebarBackgroundColor = "#" + searchParams.get("sidebackgroundcolor")
  let contentcolor = "#" + searchParams.get("contentcolor")
  let style = searchParams.get("style")
  let limit = searchParams.get("limit")
  let sort = searchParams.get("sort")
  let onlyText = searchParams.get("onlyText")

  // let primaryColor = "#" + searchParams.get("primaryColor")
  // let secondaryColor = "#" + searchParams.get("secondaryColor")
  let bgColor = "white"
  let primaryColor =
    brandSettingsData?.generalAppearance?.reviewColor ?? "#000000"
  let secondaryColor =
    `${brandSettingsData?.generalAppearance?.reviewColor}80` ?? "#333333"

  const [sidebarOpen, setSidebarOpen] = useState(false)
  const [activeTab, setActiveTab] = useState("")

  const handleToggle = (tab) => {
    setActiveTab(tab)
    setSidebarOpen(!sidebarOpen)
  }
  useEffect(() => {
    const handleIframeMessage = (event) => {
      // Check the origin of the message for security
      // if (event.origin !== 'https://www.parentdomain.com') {
      //   return; // Reject messages from unexpected origins
      // }
      console.log("iframemessage", event)
      // Handle the received message
      setActiveTab(event.data.tab)
      // Process the message or take action based on its content
      console.log("Received message in parent:", activeTab)
    }

    window.addEventListener("message", handleIframeMessage)

    return () => {
      window.removeEventListener("message", handleIframeMessage)
    }
  }, [])

  return (
    <div
      className="sidebar-wrapper"
      style={{
        "--sidebar-background": sidebarBackgroundColor,
        "--sidebar-content": contentcolor,
        "--primary-color": primaryColor,
      }}
    >
      {/* <div className={`toggle-btn-wrapper ${sidebarOpen ? "close" : ""}`}>
        <button
          className="toggle-button"
          onClick={() => handleToggle("reviews")}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="23"
            viewBox="0 0 25 23"
            fill="none"
          >
            <path
              d="M12.5 0.314453L15.5859 8.56711L24.3882 8.95174L17.493 14.4368L19.8473 22.9272L12.5 18.0645L5.15268 22.9272L7.50695 14.4368L0.611794 8.95174L9.41413 8.56711L12.5 0.314453Z"
              fill="white"
            />
          </svg>
          <span>Reviews</span>
        </button>
        {/* <button
          className="toggle-button rotated-reviews"
          onClick={handleToggle}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="23"
            viewBox="0 0 25 23"
            fill="none"
          >
            <path
              d="M12.5 0.314453L15.5859 8.56711L24.3882 8.95174L17.493 14.4368L19.8473 22.9272L12.5 18.0645L5.15268 22.9272L7.50695 14.4368L0.611794 8.95174L9.41413 8.56711L12.5 0.314453Z"
              fill="white"
            />
          </svg>
          <span>Reviews</span>
        </button> */}
      {/*   <button
          className="toggle-button"
          onClick={() => handleToggle("questions")}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="23"
            height="23"
            viewBox="0 0 23 23"
            fill="none"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M22.3393 11.644C22.3393 17.6304 17.4864 22.4834 11.5 22.4834C9.90839 22.4834 8.39691 22.1403 7.03545 21.5242C6.93761 21.5943 6.82395 21.6484 6.69604 21.6809L2.3167 22.7917C1.57053 22.981 0.899283 22.2916 1.10845 21.5507L2.29068 17.3635C1.25746 15.7033 0.660645 13.7434 0.660645 11.644C0.660645 5.65763 5.51359 0.804688 11.5 0.804688C17.4864 0.804688 22.3393 5.65763 22.3393 11.644ZM11.3385 5.82063C9.36616 5.82063 7.55553 7.22711 7.55553 8.81142C7.55553 9.45807 8.04052 9.79757 8.60634 9.79757C9.33614 9.79757 9.55122 9.33538 9.78119 8.84119C10.0454 8.27351 10.3292 7.6636 11.4355 7.6636C12.4054 7.6636 12.9874 8.18093 12.9874 9.05391C12.9874 9.80765 12.3471 10.3016 11.6757 10.8195C10.9359 11.3902 10.1583 11.99 10.1583 12.9985C10.1583 13.5158 10.4978 14.0817 11.193 14.0817C11.9535 14.0817 12.1062 13.6792 12.2717 13.2431C12.3384 13.0674 12.4072 12.8862 12.5186 12.7237C12.6746 12.4946 13.0024 12.2612 13.3844 11.9892C14.2694 11.359 15.4447 10.522 15.4447 9.05391C15.4447 6.77445 13.4078 5.82063 11.3385 5.82063ZM11.2576 15.1325C10.5301 15.1325 9.93199 15.7306 9.93199 16.4581C9.93199 17.1856 10.5301 17.7838 11.2576 17.7838C11.9851 17.7838 12.5833 17.1856 12.5833 16.4581C12.5833 15.7306 11.9851 15.1325 11.2576 15.1325Z"
              fill="white"
            />
          </svg>
          <span>Q&A</span>
        </button>
      </div> */}
      <div
        className={`sidebar-backdrop ${sidebarOpen ? "" : "open"}`}
        onClick={() => {
          setTimeout(
            () => window.parent.postMessage({ showSidebar: true }, "*"),
            200
          )
        }}
      ></div>
      <div className={`sidebar-pdp-widget ${sidebarOpen ? "" : "open"}`}>
        <Tabs
          id="uncontrolled-tab-example"
          className="pdp-tabs"
          activeKey={activeTab}
          // onSelect=
        >
          <Tab
            eventKey="reviews"
            title={
              <>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="23"
                  height="23"
                  viewBox="0 0 23 23"
                  fill="none"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M22.3393 11.644C22.3393 17.6304 17.4864 22.4834 11.5 22.4834C9.90839 22.4834 8.39691 22.1403 7.03545 21.5242C6.93761 21.5943 6.82395 21.6484 6.69604 21.6809L2.3167 22.7917C1.57053 22.981 0.899283 22.2916 1.10845 21.5507L2.29068 17.3635C1.25746 15.7033 0.660645 13.7434 0.660645 11.644C0.660645 5.65763 5.51359 0.804688 11.5 0.804688C17.4864 0.804688 22.3393 5.65763 22.3393 11.644ZM11.3385 5.82063C9.36616 5.82063 7.55553 7.22711 7.55553 8.81142C7.55553 9.45807 8.04052 9.79757 8.60634 9.79757C9.33614 9.79757 9.55122 9.33538 9.78119 8.84119C10.0454 8.27351 10.3292 7.6636 11.4355 7.6636C12.4054 7.6636 12.9874 8.18093 12.9874 9.05391C12.9874 9.80765 12.3471 10.3016 11.6757 10.8195C10.9359 11.3902 10.1583 11.99 10.1583 12.9985C10.1583 13.5158 10.4978 14.0817 11.193 14.0817C11.9535 14.0817 12.1062 13.6792 12.2717 13.2431C12.3384 13.0674 12.4072 12.8862 12.5186 12.7237C12.6746 12.4946 13.0024 12.2612 13.3844 11.9892C14.2694 11.359 15.4447 10.522 15.4447 9.05391C15.4447 6.77445 13.4078 5.82063 11.3385 5.82063ZM11.2576 15.1325C10.5301 15.1325 9.93199 15.7306 9.93199 16.4581C9.93199 17.1856 10.5301 17.7838 11.2576 17.7838C11.9851 17.7838 12.5833 17.1856 12.5833 16.4581C12.5833 15.7306 11.9851 15.1325 11.2576 15.1325Z"
                    fill="white"
                  />
                </svg>
                Reviews
              </>
            }
          >
            <Reviews
              sendMaxHeightToParent={() => {}}
              brandSettingsData={brandSettingsData}
            />
          </Tab>
          <Tab
            eventKey="questions"
            title={
              <>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="23"
                  height="23"
                  viewBox="0 0 23 23"
                  fill="none"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M22.3393 11.644C22.3393 17.6304 17.4864 22.4834 11.5 22.4834C9.90839 22.4834 8.39691 22.1403 7.03545 21.5242C6.93761 21.5943 6.82395 21.6484 6.69604 21.6809L2.3167 22.7917C1.57053 22.981 0.899283 22.2916 1.10845 21.5507L2.29068 17.3635C1.25746 15.7033 0.660645 13.7434 0.660645 11.644C0.660645 5.65763 5.51359 0.804688 11.5 0.804688C17.4864 0.804688 22.3393 5.65763 22.3393 11.644ZM11.3385 5.82063C9.36616 5.82063 7.55553 7.22711 7.55553 8.81142C7.55553 9.45807 8.04052 9.79757 8.60634 9.79757C9.33614 9.79757 9.55122 9.33538 9.78119 8.84119C10.0454 8.27351 10.3292 7.6636 11.4355 7.6636C12.4054 7.6636 12.9874 8.18093 12.9874 9.05391C12.9874 9.80765 12.3471 10.3016 11.6757 10.8195C10.9359 11.3902 10.1583 11.99 10.1583 12.9985C10.1583 13.5158 10.4978 14.0817 11.193 14.0817C11.9535 14.0817 12.1062 13.6792 12.2717 13.2431C12.3384 13.0674 12.4072 12.8862 12.5186 12.7237C12.6746 12.4946 13.0024 12.2612 13.3844 11.9892C14.2694 11.359 15.4447 10.522 15.4447 9.05391C15.4447 6.77445 13.4078 5.82063 11.3385 5.82063ZM11.2576 15.1325C10.5301 15.1325 9.93199 15.7306 9.93199 16.4581C9.93199 17.1856 10.5301 17.7838 11.2576 17.7838C11.9851 17.7838 12.5833 17.1856 12.5833 16.4581C12.5833 15.7306 11.9851 15.1325 11.2576 15.1325Z"
                    fill="white"
                  />
                </svg>
                Q&A
              </>
            }
          >
            <Questions
              primaryColor={primaryColor}
              brandSettingsData={brandSettingsData}
            />
          </Tab>
        </Tabs>
      </div>
    </div>
  )
}
