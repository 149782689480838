export const SearchIcon = () => {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.87708 6.08691C2.87708 4.03311 4.54202 2.36816 6.59583 2.36816C8.64963 2.36816 10.3146 4.03311 10.3146 6.08691C10.3146 8.14072 8.64963 9.80566 6.59583 9.80566C4.54202 9.80566 2.87708 8.14072 2.87708 6.08691ZM6.59583 1.36816C3.98973 1.36816 1.87708 3.48082 1.87708 6.08691C1.87708 8.69301 3.98973 10.8057 6.59583 10.8057C7.74099 10.8057 8.79088 10.3977 9.60811 9.71926L13.0554 13.2326L13.7692 12.5322L10.3061 9.00279C10.9378 8.20016 11.3146 7.18754 11.3146 6.08691C11.3146 3.48082 9.20192 1.36816 6.59583 1.36816Z"
        fill="black"
      />
    </svg>
  )
}

export const question = [
  {
    id: 1,
    ques: "Can nutrition patches replace a healthy diet?",
    askBy: "Alex J.",
    verified: true,
    questionTiming: "1 day ago",
    answer:
      "Combining multiple patches could lead to an excessive intake of certain nutrients, potentially causing adverse effects.",
    store: "The Friendly Patch",
    answerTiming: "1 day ago",
    likes: 5,
    dislikes: 1,
  },
  {
    id: 2,
    ques: "Can nutrition patches replace a healthy diet?",
    askBy: "Alex J.",
    verified: true,
    questionTiming: "1 day ago",
    answer:
      "Combining multiple patches could lead to an excessive intake of certain nutrients, potentially causing adverse effects.",
    store: "The Friendly Patch",
    answerTiming: "1 day ago",
    likes: 5,
    dislikes: 1,
  },
  {
    id: 3,
    ques: "Can nutrition patches replace a healthy diet?",
    askBy: "Alex J.",
    verified: true,
    questionTiming: "1 day ago",
    answer:
      "Combining multiple patches could lead to an excessive intake of certain nutrients, potentially causing adverse effects.",
    store: "The Friendly Patch",
    answerTiming: "1 day ago",
    likes: 5,
    dislikes: 1,
  },
  {
    id: 4,
    ques: "Can nutrition patches replace a healthy diet?",
    askBy: "Alex J.",
    verified: true,
    questionTiming: "1 day ago",
    answer:
      "Combining multiple patches could lead to an excessive intake of certain nutrients, potentially causing adverse effects.",
    store: "The Friendly Patch",
    answerTiming: "1 day ago",
    likes: 5,
    dislikes: 1,
  },
  {
    id: 5,
    ques: "Can nutrition patches replace a healthy diet?",
    askBy: "Alex J.",
    verified: true,
    questionTiming: "1 day ago",
    answer:
      "Combining multiple patches could lead to an excessive intake of certain nutrients, potentially causing adverse effects.",
    store: "The Friendly Patch",
    answerTiming: "1 day ago",
    likes: 5,
    dislikes: 1,
  },
]

export const VerifiedIcon = ({ bgColor }) => {
  return (
    <svg
      width="12"
      height="14"
      viewBox="0 0 12 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.14117 13.228C9.34569 13.228 11.9435 10.4045 11.9435 6.92161C11.9435 3.4387 9.34569 0.615234 6.14117 0.615234C2.93665 0.615234 0.338867 3.4387 0.338867 6.92161C0.338867 10.4045 2.93665 13.228 6.14117 13.228Z"
        fill={bgColor}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.21016 9.4257L3.31761 7.38106C3.26473 7.32358 3.23828 7.24967 3.23828 7.15935C3.23828 7.06902 3.26472 6.99512 3.31761 6.93765L3.73692 6.49423C3.7898 6.42854 3.85592 6.3957 3.93524 6.3957C4.01457 6.3957 4.08445 6.42854 4.14489 6.49423L5.41414 7.87375L8.13397 4.91763C8.19441 4.85195 8.2643 4.81909 8.34362 4.81909C8.42296 4.81909 8.48906 4.85194 8.54194 4.91763L8.96125 5.36105C9.01413 5.41853 9.04058 5.49244 9.04058 5.58276C9.04058 5.67309 9.01414 5.74698 8.96125 5.80446L5.61812 9.42571C5.56524 9.4914 5.49724 9.52425 5.41413 9.52425C5.33103 9.52425 5.26303 9.49141 5.21015 9.42571L5.21016 9.4257Z"
        fill="white"
      />
    </svg>
  )
}

export const StoreIcon = () => {
  return (
    <svg
      width="13"
      height="12"
      viewBox="0 0 13 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <rect
        x="0.688477"
        y="0.393555"
        width="11.5977"
        height="11.5977"
        fill="url(#pattern0)"
      />
      <defs>
        <pattern
          id="pattern0"
          patternContentUnits="objectBoundingBox"
          width="1"
          height="1"
        >
          <use xlinkHref="#image0_2365_59334" transform="scale(0.01)" />
        </pattern>
        <image
          id="image0_2365_59334"
          width="100"
          height="100"
          xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGQAAABkCAYAAABw4pVUAAAACXBIWXMAAAsTAAALEwEAmpwYAAAEbklEQVR4nO2dN29UQRSFj8k0BImCKKAGCpIQDUkig1yZvwCI2BMFQsikChpCSWNhOhAUhIKGLBBQQgWYnMGWLA8aaSxZlvft3Lvvzbu7ez7pdjvpHO7c52XmLUAIIYQQQgghhJDGYgKAgwCeAPgNwDVZ/AbwGMCBoEWprADw0YAozkh8ALC8LDMWA/hrQARnLLwmi1KbMRzACwOLd0bjedAoGesMLNoZjzUpDTlpYMHOeLSnNORyxIT8lnYDQJdgEV2hzUsDgtYaXqNkdGRM5AeA9QM+OwrAqYgFnAQwckC7DQB+GhBWGx1WDNk+xOdbANzJaHMrfGYwOwwIW/eGTKnQZk9Gm10V2kw2IGzDGrI3o83uCm2mGRC27g3x28xg/HZ0N6PN7Qpb1k4Dwta9Ib4Qbxzw2dEAzkQs4HR4AOhnE4BfBoSte0P64xWAm+H7ndhFfAhtXhkQtOEMafbooCEo3QQagvKFpyEoX2wagvIFpiEoX9S6MGQGgE8RE+oGMD2yzweRi2xTzLctsm8/hxj8NxH/Ivr7CmAWCmYEgHuRCzwb2ec4AL0GDOkFMD6yz3ORfd4f9Mduaf8xJcmOjYJtoK1AQ1z4yj+GqZFZ4uMECsJPti/n7ECYsBVD2gX9xmaJ16wVJdUNaXZI6odLYIjfYlBAluRaTyR1Q5odkvrhEhgiqSOSLMm1nkgONEizQ1I/XAJDJHVEmiW51BNJ3ZBmh7R+uESGSE+LSLKkpnoiqRua7JDWD5fIEEkd0WSJqp5I64YmO6T1wyUyRFpHpFmiqifSg3A9AGYKFyGtHy6RIdI60r+bdAvHOFFU3dBkh6Z+uISGaE4dSrMkqp5I64Y2OzT1wyU0RFpHtFlStZ6cV0z+CnRorjK0KsZpVYzj56bhimIsr3muh6i1h4rfKMZaqLzHIh3ntXJNueu3VtHhauXkLwrH+aS8c+GfGD/n+a82pX7DADwVdPYstNEwT/jYux96DgvG8XOaoxynEP0WRe7v/jMLUBuHIif+AMCYGsYZC+BR5Fj+AmstFKLf8ioH3Pw9jmWonZZgSm+Vk/GTchhrUpUT+L3BjKGOtEopRL/xYZt4FI50+ngIYF/4KztP5gK4EAp9T5jwtfDImodA/fi+tgC4HsboCQX8fA3blAX9CCGEEEJIISwNj6RDRbPRViG8RiYu7DQbzvqFnWbD0RBb0BBj0BBj0BBj0BBj0BBj0BBj0BBj0BBj0BBj0BBj0BBj0BBj0BBj0BBj0BBj0BBj0BBj0BBj0BBj0BBjmDDkkuLd743I1Awd/JW9ZBzNmMhWNA/bMnQ4knIiWVeNv9VwEbSe8Bc8v2fosDnlZMZW+RHJvvCTR51hL22k6Aw/yZT1totfQaOktEdcbGzWOIYSmAjgnYHFO2PxVvE2odzwb1b4Y0AEZyT8e7OWoGRWAfhiQAxXcvi3RayEEWYDuGpAFFdSdKZ4i7UG/2aC4+E+tuQHJest3oc1+rXOL1t0QgghhBBCCCGEoCD+A6shAM2x1OtqAAAAAElFTkSuQmCC"
        />
      </defs>
    </svg>
  )
}

export const Dislike = ({ color }) => {
  return (
    <svg
      width="14"
      height="13"
      viewBox="0 0 14 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.82665 0.545794C9.50532 0.39267 9.15386 0.31319 8.7979 0.31319H3.6848C2.50676 0.31319 1.50433 1.17111 1.32242 2.33489L0.723788 6.16375C0.54245 7.3239 1.43948 8.37207 2.61369 8.37207H5.75435V10.627C5.75435 11.4855 6.4503 12.1814 7.30879 12.1814C7.87146 12.1814 8.3902 11.8773 8.66509 11.3864L10.6519 7.83796C10.7718 7.62382 10.8348 7.3825 10.8348 7.13707V1.93176C10.8348 1.37865 10.5168 0.874711 10.0174 0.636751L9.82665 0.545794ZM13.0471 7.6627C13.2998 7.6627 13.5422 7.56231 13.7209 7.3836C13.8996 7.20488 14 6.9625 14 6.70977L14 1.81995C14 1.56716 13.8996 1.32481 13.7209 1.14605C13.5422 0.967388 13.2998 0.866963 13.0471 0.866963L12.5721 0.867155C12.3081 0.86725 12.0941 1.0814 12.0941 1.34537V7.18469C12.0941 7.31156 12.1445 7.43323 12.2343 7.52291C12.324 7.61261 12.4457 7.66296 12.5725 7.6629L13.0471 7.6627Z"
        fill={color}
      />
    </svg>
  )
}
export const Dislike1 = ({ color }) => {
  return (
    <svg
      width="14"
      height="13"
      viewBox="0 0 14 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.82665 0.545794C9.50532 0.39267 9.15386 0.31319 8.7979 0.31319H3.6848C2.50676 0.31319 1.50433 1.17111 1.32242 2.33489L0.723788 6.16375C0.54245 7.3239 1.43948 8.37207 2.61369 8.37207H5.75435V10.627C5.75435 11.4855 6.4503 12.1814 7.30879 12.1814C7.87146 12.1814 8.3902 11.8773 8.66509 11.3864L10.6519 7.83796C10.7718 7.62382 10.8348 7.3825 10.8348 7.13707V1.93176C10.8348 1.37865 10.5168 0.874711 10.0174 0.636751L9.82665 0.545794ZM13.0471 7.6627C13.2998 7.6627 13.5422 7.56231 13.7209 7.3836C13.8996 7.20488 14 6.9625 14 6.70977L14 1.81995C14 1.56716 13.8996 1.32481 13.7209 1.14605C13.5422 0.967388 13.2998 0.866963 13.0471 0.866963L12.5721 0.867155C12.3081 0.86725 12.0941 1.0814 12.0941 1.34537V7.18469C12.0941 7.31156 12.1445 7.43323 12.2343 7.52291C12.324 7.61261 12.4457 7.66296 12.5725 7.6629L13.0471 7.6627Z"
        fill={color}
      />
    </svg>
  )
}

export const Like = ({ color }) => {
  return (
    <svg
      width="14"
      height="13"
      viewBox="0 0 14 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.57374 11.9488C4.89507 12.102 5.24653 12.1814 5.60249 12.1814H10.7156C11.8936 12.1814 12.8961 11.3235 13.078 10.1597L13.6766 6.33088C13.8579 5.17073 12.9609 4.12256 11.7867 4.12256H8.64604V1.86767C8.64604 1.00918 7.95009 0.313232 7.0916 0.313232C6.52893 0.313232 6.01019 0.617298 5.7353 1.10825L3.74847 4.65667C3.62856 4.87081 3.5656 5.11213 3.5656 5.35756V10.5629C3.5656 11.116 3.88363 11.6199 4.383 11.8579L4.57374 11.9488ZM1.35334 4.83193C1.1006 4.83193 0.858213 4.93232 0.679502 5.11103C0.50079 5.28975 0.400391 5.53213 0.400391 5.78486L0.400391 10.6747C0.400391 10.9275 0.50079 11.1698 0.679502 11.3486C0.858212 11.5272 1.1006 11.6277 1.35334 11.6277L1.82827 11.6275C2.0923 11.6274 2.30627 11.4132 2.30627 11.1493V5.30994C2.30627 5.18307 2.25586 5.0614 2.16614 4.97172C2.0764 4.88202 1.95472 4.83167 1.82786 4.83172L1.35334 4.83193Z"
        fill={color}
      />
    </svg>
  )
}

export const Like1 = ({ color }) => {
  return (
    <svg
      width="14"
      height="13"
      viewBox="0 0 14 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.57374 11.9488C4.89507 12.102 5.24653 12.1814 5.60249 12.1814H10.7156C11.8936 12.1814 12.8961 11.3235 13.078 10.1597L13.6766 6.33088C13.8579 5.17073 12.9609 4.12256 11.7867 4.12256H8.64604V1.86767C8.64604 1.00918 7.95009 0.313232 7.0916 0.313232C6.52893 0.313232 6.01019 0.617298 5.7353 1.10825L3.74847 4.65667C3.62856 4.87081 3.5656 5.11213 3.5656 5.35756V10.5629C3.5656 11.116 3.88363 11.6199 4.383 11.8579L4.57374 11.9488ZM1.35334 4.83193C1.1006 4.83193 0.858213 4.93232 0.679502 5.11103C0.50079 5.28975 0.400391 5.53213 0.400391 5.78486L0.400391 10.6747C0.400391 10.9275 0.50079 11.1698 0.679502 11.3486C0.858212 11.5272 1.1006 11.6277 1.35334 11.6277L1.82827 11.6275C2.0923 11.6274 2.30627 11.4132 2.30627 11.1493V5.30994C2.30627 5.18307 2.25586 5.0614 2.16614 4.97172C2.0764 4.88202 1.95472 4.83167 1.82786 4.83172L1.35334 4.83193Z"
        fill={color}
      />
    </svg>
  )
}

export const StarIcon = ({ fillPercentage, color }) => {
  // Ensure the fillPercentage is within the valid range (0 to 100)
  const percentage = Math.max(0, Math.min(fillPercentage || 0, 100))

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill={color ?? "none"}
      stroke={color}
      strokeWidth="1.5"
    >
      <defs>
        <linearGradient
          id={`star-fill-${percentage}`}
          x1="0%"
          y1="0%"
          x2="100%"
          y2="0%"
        >
          <stop offset={`${percentage}%`} stopColor={color} />
          <stop offset={`${percentage}%`} stopColor="#fff" />
        </linearGradient>
      </defs>
      <path
        d="M12.5275 0.660156L15.6905 9.11913L24.7129 9.51337L17.6453 15.1355L20.0585 23.8382L12.5275 18.8539L4.99646 23.8382L7.40959 15.1355L0.342053 9.51337L9.36444 9.11913L12.5275 0.660156Z"
        fill={`url(#star-fill-${percentage})`}
      />
      {/* <polygon
        points="12 2 15.09 9.15 22 10.5 17 16.01 18.18 22 12 19.5 5.82 22 7 16.01 2 10.5 8.91 9.15 12 2"
        fill={`url(#star-fill-${percentage})`}
      /> */}
    </svg>
  )
}

export const SimpleStar = ({ color }) => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.5275 0.660156L15.6905 9.11913L24.7129 9.51337L17.6453 15.1355L20.0585 23.8382L12.5275 18.8539L4.99646 23.8382L7.40959 15.1355L0.342053 9.51337L9.36444 9.11913L12.5275 0.660156Z"
        fill={color}
      />
    </svg>
  )
}
export const EmptyStar = ({ color }) => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.5275 0.660156L15.6905 9.11913L24.7129 9.51337L17.6453 15.1355L20.0585 23.8382L12.5275 18.8539L4.99646 23.8382L7.40959 15.1355L0.342053 9.51337L9.36444 9.11913L12.5275 0.660156Z"
        stroke={color}
        fill="none"
      />
    </svg>
  )
}

export const HeartIcon = ({ color }) => {
  return (
    <svg
      width="14"
      height="13"
      viewBox="0 0 14 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.09955 0.736846C1.66594 1.24767 0.710204 2.69776 0.693726 4.37854C0.693726 5.68033 1.40229 7.14689 2.90181 8.91007C4.15416 10.3766 6.82364 12.6836 7.28503 12.6836C7.74643 12.6836 10.4159 10.3766 11.6683 8.91007C13.1678 7.14689 13.8763 5.68033 13.8763 4.37854C13.8599 1.04993 10.3335 -0.647328 7.58164 1.34654C7.33447 1.51132 7.2356 1.51132 7.0049 1.34654C5.83495 0.50615 4.36838 0.275455 3.09955 0.736846Z"
        fill={color}
      />
    </svg>
  )
}

export const RedHeart = () => {
  return (
    <svg
      width="14"
      height="13"
      viewBox="0 0 14 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.01691 0.930205C1.5833 1.44103 0.627562 2.89112 0.611084 4.5719C0.611084 5.87369 1.31965 7.34025 2.81917 9.10343C4.07152 10.57 6.741 12.877 7.20239 12.877C7.66378 12.877 10.3333 10.57 11.5856 9.10343C13.0851 7.34025 13.7937 5.87369 13.7937 4.5719C13.7772 1.24329 10.2509 -0.453969 7.499 1.5399C7.25183 1.70468 7.15296 1.70468 6.92226 1.5399C5.75231 0.69951 4.28574 0.468814 3.01691 0.930205Z"
        fill="#EB2136"
      />
    </svg>
  )
}

export const TaskCompleted = () => {
  return (
    <svg
      width="52"
      height="52"
      viewBox="0 0 52 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <rect
        x="0.544922"
        y="0.503906"
        width="50.707"
        height="50.707"
        fill="url(#pattern100)"
      />
      <defs>
        <pattern
          id="pattern100"
          patternContentUnits="objectBoundingBox"
          width="1"
          height="1"
        >
          <use xlinkHref="#image0_2434_58182" transform="scale(0.01)" />
        </pattern>
        <image
          id="image0_2434_58182"
          width="100"
          height="100"
          xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGQAAABkCAYAAABw4pVUAAAACXBIWXMAAAsTAAALEwEAmpwYAAADiUlEQVR4nO2dO2sUYRSGH6OF93+gGCsrV9DKRmMVBbWy04i9iIIYxUZEExv9E1ZpLaxsUhjxErDXQgsrRQsbLyFHPpiFjWx2Z3dn5pyZeV94ISQMc8775LvsZDIDkiRJktQu7QDOA0+A58Ab4HX2dfreOWC7d5Ft0F5gEfgG2BB/BRayY6QSdBr4kgPE/07HzIpIsboGrI0Bo+t07FVBKUZXJgDR63XgsqBMpiPAn4KAJP8GDgvKeJoC3hYIo+u0G9siKKPrbAkwuj7TBiAHgTngJjBfgFdzhvsOeJw57zGrBdWYer0ETBNIp4BXJf422yZOa8vFPvXMFbzu5PUKMIPzHL/o0LhlvjWgtttONaWd3MMsm8rlCeMXsHtAbXuyHZVXfQlK5dPUumPDH3LU+NGxvvWqpy+PNcN6/H3ItJB+9sO5xrSmVLabsgCeHVDjmQD1WVW7r7kAjRrwCdjXp779wOcA9dkmu8DCNR+gUeu5xH4HOJ75bs5L81U5ZVW67gVo1GrilJWA4A9CQPAPX0DwD1xA8A9ZQPAPVkDwD1NA8A9QQAKEZgLiH5QJiH84JiD+gZiA+IdggaxrWYwW2PsJb1EVEIoLazn7u/wF4K+AxIDRVVlQNGUxOowyoQgI48EoC4qAMD6MMqAICP2DeQHsJL+eCkh1C/gwXdeUtTHANceRcUOL+sYAl4Cj2e09dR4ZjVhDloBt2Tk6E0JJMHY5w6g1kF4YTAglCozaAukHY1wokWDUEsggGKNCiQajdkCeAVtznrMz5J5d791UI4Ck//E4NsJ5O5uMlIgjo5ZAioCyHBhGLYFMAmU5OIzaArEs4M4INRzKnpkVbc1oDJBxRkrkkdEIIFYCFE8YjQBiBULxhtEYIJZBSRcZ6wyjUUBsAihRYDQOiI0BJRKMRgKxEaBEg9FYIJYDSkQYjQZiA6BEhdF4INYHSmQYrQBiPVCiw2gNEAN+BqhBQPAPWEDwD1VA8A9SQAKEZwLiH5gJiH9IJiD+wZiA+IdhAdyaD4ZWEwsI/hAEBP/gBQT/sAUE/4AFBP9QwwOJ9KhxC+5BL51p3MP4rQau5GH8BwI0ajVxZS8LWwnQrAX3SyrUjPMrjyy4UzYnqFgLARq3oL6Pg6ayt5FppLBhZDzwfrXrSa0pdNeMyqepQZrOtnnpc8qjlng+6zntPCVJkiRJkiRJkiRJkiSJNukf2dx5T223ESQAAAAASUVORK5CYII="
        />
      </defs>
    </svg>
  )
}

export const QuoliLogo = () => {
  return (
    <svg
      width="74"
      height="26"
      viewBox="0 0 74 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.4634 12.9801L15.3773 15.7922C15.393 15.84 15.4233 15.8817 15.4641 15.9113C15.5049 15.9409 15.5539 15.9568 15.6043 15.9569H18.567C18.6173 15.957 18.6663 15.973 18.707 16.0027C18.7477 16.0323 18.778 16.074 18.7936 16.1219C18.8092 16.1698 18.8093 16.2213 18.7938 16.2692C18.7784 16.3172 18.7482 16.359 18.7076 16.3887L16.3113 18.1362C16.2705 18.1654 16.2401 18.2069 16.2246 18.2546C16.209 18.3023 16.2091 18.3537 16.2249 18.4013L17.1388 21.2134C17.1544 21.2613 17.1543 21.313 17.1387 21.3609C17.1231 21.4088 17.0927 21.4506 17.0519 21.4802C17.0112 21.5098 16.9621 21.5258 16.9117 21.5259C16.8612 21.5259 16.8121 21.51 16.7713 21.4805L14.375 19.7451C14.3344 19.7158 14.2855 19.7001 14.2354 19.7001C14.1853 19.7001 14.1365 19.7158 14.0958 19.7451L11.6996 21.4865C11.6588 21.5161 11.6096 21.532 11.5592 21.5319C11.5088 21.5319 11.4597 21.5159 11.4189 21.4862C11.3782 21.4566 11.3478 21.4148 11.3322 21.3669C11.3166 21.319 11.3165 21.2673 11.332 21.2194L12.2479 18.4074C12.2629 18.3595 12.2624 18.3081 12.2465 18.2605C12.2307 18.2129 12.2002 18.1716 12.1596 18.1422L9.76331 16.3947C9.72269 16.365 9.69251 16.3232 9.67706 16.2753C9.66161 16.2274 9.66168 16.1758 9.67727 16.1279C9.69286 16.0801 9.72316 16.0383 9.76385 16.0087C9.80455 15.9791 9.85356 15.963 9.90391 15.9629H12.8666C12.9169 15.9629 12.966 15.9469 13.0068 15.9173C13.0475 15.8878 13.0779 15.8461 13.0935 15.7982L14.0075 12.9862C14.0231 12.9384 14.0533 12.8968 14.0938 12.8671C14.1343 12.8373 14.1831 12.821 14.2333 12.8203C14.2835 12.8197 14.3327 12.8347 14.3739 12.8634C14.4152 12.892 14.4465 12.9328 14.4634 12.9801Z"
        fill="#979797"
      />
      <path
        d="M9.63806 18.467C8.47971 18.4339 7.35669 18.0607 6.4088 17.3941C5.46092 16.7275 4.73004 15.7968 4.30716 14.7179C3.88427 13.639 3.78805 12.4595 4.03048 11.3263C4.27291 10.1932 4.84328 9.1563 5.67059 8.34486C6.4979 7.53341 7.54559 6.98322 8.68326 6.76277C9.82092 6.54232 10.9983 6.66135 12.0688 7.10504C13.1393 7.54874 14.0557 8.29749 14.7039 9.2581C15.352 10.2187 15.7033 11.3487 15.714 12.5075H19.0302C19.0193 10.6929 18.4732 8.92182 17.4605 7.41602C16.4478 5.91022 15.0135 4.7366 13.337 4.04209C11.6605 3.34758 9.81635 3.16304 8.03548 3.51156C6.2546 3.86009 4.6161 4.7262 3.3251 6.00146C2.0341 7.27673 1.14796 8.90448 0.77762 10.6809C0.407279 12.4574 0.569192 14.3037 1.24309 15.9885C1.91699 17.6734 3.07293 19.1221 4.56619 20.1532C6.05946 21.1843 7.8237 21.752 9.63806 21.7852V18.467Z"
        fill="#979797"
      />
      <path
        d="M34.7372 18.5601C35.6971 18.7061 36.5318 19.1861 37.4082 19.9164L36.3857 21.1476C34.9667 20.1877 34.299 20.0208 33.5686 20.0208C32.9844 20.0208 32.6296 20.0834 32.0453 20.2086C31.5863 20.3129 31.1063 20.3546 30.5638 20.3546C26.1816 20.3546 23.761 17.2454 23.761 12.8633C23.761 8.46031 26.1816 5.37196 30.5638 5.37196C34.9459 5.37196 37.3665 8.46031 37.3665 12.8633C37.3665 15.2839 36.4483 17.308 34.7372 18.5601ZM25.7643 12.8633C25.7643 16.5985 27.538 18.9357 30.5638 18.9357C33.5895 18.9357 35.3632 16.5985 35.3632 12.8633C35.3632 9.1072 33.5895 6.79093 30.5638 6.79093C27.538 6.79093 25.7643 9.1072 25.7643 12.8633ZM48.81 9.00286V20.0625H47.0155V18.1844L46.3268 19.4782C45.3878 20.1668 44.574 20.3546 43.4472 20.3546C41.1518 20.3546 39.8163 19.0609 39.5659 17.5376C39.4824 16.9741 39.4615 16.5359 39.4615 15.6386V9.00286H41.2561V15.3048C41.2561 15.6595 41.277 16.2647 41.3396 16.8281C41.5065 18.0801 42.4038 18.9357 43.9062 18.9357C45.6382 18.9357 47.0155 17.6419 47.0155 13.8649V9.00286H48.81ZM56.5476 8.71072C60.3037 8.71072 62.0356 11.4026 62.0356 14.5327C62.0356 17.6419 60.3037 20.3546 56.5476 20.3546C52.7914 20.3546 51.0595 17.6419 51.0595 14.5327C51.0595 11.4026 52.7914 8.71072 56.5476 8.71072ZM56.5476 18.9774C58.7803 18.9774 60.0741 17.1202 60.0741 14.5327C60.0741 11.9243 58.7803 10.088 56.5476 10.088C54.3148 10.088 53.021 11.9243 53.021 14.5327C53.021 17.1202 54.3148 18.9774 56.5476 18.9774ZM65.9156 4.72507V16.8907C65.9156 17.2246 65.9156 17.5793 65.9365 17.934C65.9782 18.6435 66.3121 18.8731 67.2929 18.7687V20.0625C66.8964 20.1251 66.4373 20.146 66.02 20.146C64.9766 20.146 64.2045 19.8121 64.1419 18.414C64.1211 18.0384 64.1211 17.5584 64.1211 16.9533V4.72507H65.9156ZM70.2575 5.37196C70.9044 5.37196 71.4052 5.87277 71.4052 6.51966C71.4052 7.16654 70.9044 7.66736 70.2575 7.66736C69.6106 7.66736 69.1098 7.16654 69.1098 6.51966C69.1098 5.87277 69.6106 5.37196 70.2575 5.37196ZM71.1548 20.0625H69.3602V9.00286H71.1548V20.0625Z"
        fill="#979797"
      />
    </svg>
  )
}

export const VideoBtn = () => {
  return (
    <svg
      width="52"
      height="52"
      viewBox="0 0 52 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25.7896 0.511719C11.6486 0.511719 0.18927 11.971 0.18927 26.112C0.18927 40.2479 11.6486 51.7038 25.7896 51.7038C39.9272 51.7038 51.3882 40.2462 51.3882 26.112C51.3899 11.971 39.9272 0.511719 25.7896 0.511719ZM25.7896 47.4326C14.0134 47.4326 4.46564 37.8899 4.46564 26.112C4.46564 14.3392 14.0134 4.78468 25.7896 4.78468C37.5641 4.78468 47.1101 14.3375 47.1101 26.112C47.1118 37.8899 37.5641 47.4326 25.7896 47.4326Z"
        fill="white"
      />
    </svg>
  )
}

export const ArrowReview = () => {

}