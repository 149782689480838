import React from "react"
import { Dropdown } from "react-bootstrap"
import "./CustomDropdown.scss"

const CustomDropdown = ({ items, textBefore, ratings, onSelect, value }) => {
  const [selectedItem, setSelectedItem] = React.useState(value)

  const handleDropdownSelect = (eventKey) => {
    console.log("eventKey", items[eventKey])
    console.log("eventKey", [eventKey])
    console.log("eventKey", items)

    setSelectedItem(
      items[eventKey]?.value !== undefined ? items[eventKey]?.value : eventKey
    )
    onSelect && onSelect(eventKey)
  }

  const renderItem = (item) => {
    return item.label
  }

  return (
    <div className="pdp-custom-dropdown">

      <Dropdown onSelect={handleDropdownSelect}>
        <Dropdown.Toggle variant="primary" id="dropdown-basic">
          {textBefore}:

          {/* {items.find((item) => item?.value === selectedItem)?.label ||
            selectedItem?.value} */}
          <svg xmlns="http://www.w3.org/2000/svg" width="11" height="11" viewBox="0 0 11 11" fill="none">
            <path d="M5.50037 7.84806L1.70312 4.05082L2.58915 3.16479L5.50037 6.07602L8.41159 3.16479L9.29761 4.05082L5.50037 7.84806Z" fill="black" />
          </svg>
        </Dropdown.Toggle>

        <Dropdown.Menu>
          {items.map((item, index) => (
            <Dropdown.Item key={index} eventKey={item?.value}>
              {renderItem(item)}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  )
}

export default CustomDropdown
