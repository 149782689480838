import "./AddBtn.scss"

const AddBtn = ({ btnText, onClick, ctaColor, ctaFontSize }) => {
  console.log("ctaColor", ctaColor)
  console.log(ctaFontSize, "ajshgdjhsagdds")
  return (
    <button
      className="pdp-add-btn"
      style={{
        fontSize: ctaFontSize,
        background: ctaColor?.ctaFillColor ?? "#fff",
        color: ctaColor?.ctaTextColor ?? "#000",
        // borderColor: ctaColor?.ctaBorderColor ?? "#e7e7e7",
        borderRadius: "6px",
      }}
      onClick={onClick}
    >
      {btnText}
    </button>
  )
}

export default AddBtn
