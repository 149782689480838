import CryptoJS from "crypto-js"
import { uploadFile } from "react-s3"
import { Buffer } from "buffer"
import moment from "moment"
import "../components/PDPWidget/Reviews/SingleReview/SingleReview.scss"
import RatingBarBoxes from "../components/RatingBarBoxes"
import { RedHeart } from "../components/PDPWidget/PDPConstants/PDPContants"
import ApiManager from "../classes/ApiManager"

// export const link = "http://localhost:5001/api/"
export const link = process.env.REACT_APP_API_URL || "https://api.quoli.io/api/"

export const WRAPPER_STYLE = { width: 250, marginBottom: 20 }

export const S3_URL = "quoli-reviews.s3"
export const OLD_S3_URL = "https://shopify-review-app.s3.amazonaws.com/"
export const OLD_S3_URL2 =
  "https://shopify-review-app.s3.us-east-2.amazonaws.com/"

export const CF_URL = "https://images.quoli.io/"
export const THUMBNAIL_URL = "thumbnails/"
export const RATING_BOXES = [
  {
    label: 1,
    value: 1,
  },
  {
    label: 2,
    value: 2,
  },
  {
    label: 3,
    value: 3,
  },
  {
    label: 4,
    value: 4,
  },
  {
    label: 5,
    value: 5,
  },
]

export const MONTHS = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
]
export const MONTHSSMALL = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "July",
  "Aug",
  "Sept",
  "Oct",
  "Nov",
  "Dec",
]
const config = {
  bucketName: "quoli-reviews",
  region: "us-east-2",
  accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY_ID || "AKIARGRBDKTPYNRKSWWI",
  secretAccessKey:
    process.env.REACT_APP_S3_SECRET_ACCESS_KEY ||
    "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",
}

Buffer.from(uploadFile, "base64")
window.Buffer = window.Buffer || require("buffer").Buffer
//upload file to s3

export function decryptData(data) {
  // crypto.getRandomValues()
  data = data
    ?.toString()
    ?.replace("xMl3Jk", "+")
    ?.replace("Por21Ld", "/")
    ?.replace("Ml32", "=")
  var words = CryptoJS.enc.Base64.parse(data)
  var decryptedString = CryptoJS.enc.Utf8.stringify(words)

  if (decryptedString) {
    decryptedString = JSON.parse(decryptedString)
  }
  console.log("dec===>", decryptedString)
  return decryptedString
}

export function encryptData(data) {
  var words = CryptoJS.enc.Utf8.parse(JSON.stringify(data)) // WordArray object
  var base64 = CryptoJS.enc.Base64.stringify(words) // string: 'SGVsbG8gd29ybGQ='
  console.log("base64===>", base64)
  return base64
    ?.toString()
    ?.replace("+", "xMl3Jk")
    ?.replace("/", "Por21Ld")
    ?.replace("=", "Ml32")
}

// export async function uploadToS3(file) {
//   window.Buffer = window.Buffer || Buffer

//   let image = await S3FileUpload.uploadFile(file, config)
//   return image.location
// }
export const uploadToS3 = (file) => {
  console.log(file)
  return new Promise((resolve, reject) => {
    uploadFile(file, config)
      .then((result) => resolve(result))
      .catch((error) => reject(error))
  })
}

export const encode = (string) => {
  return string
    ?.replace("s", "xMl3Jk")
    ?.replace("p", "Por21Ld")
    ?.replace("a", "Ml32")
    ?.replace("f", "jee")
}

export const formatDate = (date) => {
  return new Date(date).toLocaleDateString("en-US")
}

// export const getAverageRating = (reviewStats, reviews) => {
//   let sum = 0
//   reviewStats?.map((item) => {
//     sum += item._id * item.count
//   })
//   return isNaN((sum / reviews?.totalDocs).toFixed(1) || 0)
//     ? 0
//     : isFinite((sum / reviews?.totalDocs).toFixed(1) || 0)
//     ? (sum / reviews?.totalDocs).toFixed(1) > 5
//       ? 5
//       : (sum / reviews?.totalDocs).toFixed(1) || 0
//     : 0
// }

export const getAverageRating = (reviewStats, reviewCount) => {
  // Check if reviewStats is not an array or if it's empty
  if (!Array.isArray(reviewStats) || reviewStats.length === 0) {
    console.log("Invalid reviewStats:", reviewStats)
    return 0 // or any default value you prefer
  }

  let sum = 0
  // Use forEach instead of map to avoid creating a new array
  reviewStats.forEach((item) => {
    // Check if item has the necessary properties
    if (
      item &&
      typeof item._id === "number" &&
      typeof item.count === "number"
    ) {
      sum += item._id * item.count
    } else {
      // console.error("Invalid item:", item)
      console.log("Invalid item:", item)
    }
  })

  console.log("summision", sum)
  console.log("totalDocs", reviewCount)

  const averageRating = sum / reviewCount

  console.log("Average Rating:", averageRating)

  // Check if averageRating is a valid number
  if (isNaN(averageRating) || !isFinite(averageRating)) {
    console.log("Invalid averageRating:", averageRating)
    return 0
  }

  console.log(averageRating, "==>averageRating")
  // Check if the averageRating is a whole number, return it without decimal places
  const roundedRating = Math.round(averageRating)
  if (roundedRating === averageRating) {
    return roundedRating
  }

  console.log(roundedRating, "==>roundedRating")

  // Otherwise, return the average rating with one decimal place
  return parseFloat(averageRating.toFixed(1))
}

export const getRating = (reviewStats, rating) => {
  const value = isNaN(
    parseInt(reviewStats?.filter((item) => item._id == rating)[0]?.count ?? 0)
  )
    ? 0
    : parseInt(reviewStats?.filter((item) => item._id == rating)[0]?.count ?? 0)
  return value
}
export const getTimeFromNow = (time) => {
  const diffInSeconds = moment().diff(moment(time), "seconds")

  if (diffInSeconds < 60) {
    return `${diffInSeconds} seconds ago`
  }

  const diffInMinutes = Math.floor(diffInSeconds / 60)

  if (diffInMinutes < 60) {
    return `${diffInMinutes} ${diffInMinutes > 1 ? "minutes" : "minute"} ago`
  }

  const diffInHours = Math.floor(diffInMinutes / 60)

  if (diffInHours < 24) {
    return `${diffInHours} ${diffInHours > 1 ? "hours" : "hour"} ago`
  }

  const diffInDays = Math.floor(diffInHours / 24)

  if (diffInDays < 7) {
    return `${diffInDays} ${diffInDays > 1 ? "days" : "day"} ago`
  }

  if (diffInDays >= 7 && diffInDays <= 13) {
    return "1 week ago"
  }

  if (diffInDays > 13 && diffInDays < 30) {
    const weeks = Math.floor(diffInDays / 7)
    return `${weeks} ${weeks > 1 ? "weeks" : "week"} ago`
  }

  if (diffInDays >= 30 && diffInDays <= 45) {
    return "1 month ago"
  }

  if (diffInDays > 45 && diffInDays < 365) {
    const months = Math.floor(diffInDays / 30)
    return `${months} ${months > 1 ? "months" : "month"} ago`
  }

  const diffInYears = Math.floor(diffInDays / 365)

  return `${diffInYears} ${diffInYears > 1 ? "years" : "year"} ago`
}

export const getInitals = (name) => {
  return name
    ?.split(" ")
    ?.map((n) => n[0])
    ?.join("")
}
export const getPerformance = (rating) => {
  let performance = "Poor"
  if (rating > 1 && rating < 2) {
    performance = "Bad"
  } else if (rating > 2 && rating < 3) {
    performance = "Average"
  } else if (rating > 3 && rating < 4) {
    performance = "Good"
  } else if (rating > 4) {
    performance = "Excellent"
  }
  return performance
}

export const getAttribute = (
  attribute,
  primaryColor,
  review,
  likeUnlikeReview
) => {
  console.log("Asdadsas", attribute?.attribute)

  if (attribute?.attribute?.widgetType === 3)
    return (
      <div className=" col-5 ">
        <div className="top-effectiveness-wrapper">
          <div className="top-effectiveness">
            <div className="rating-bar-wrapper">
              <span
                className="effectiveness-text effectiveness-text-heading mb-2"
                style={{ color: primaryColor }}
              >
                {attribute?.attribute?.question}
              </span>
              <RatingBarBoxes
                rating={attribute?.totalEfc / attribute?.totalCount}
                primaryColor={primaryColor}
              />
            </div>

            <div
              className="worked d-flex justify-content-between"
              style={{ color: primaryColor }}
            >
              <span
                className="effectiveness-text"
                style={{ color: primaryColor }}
              >
                {attribute?.attribute?.answers?.[0]?.label}
              </span>
              <span
                className="effectiveness-text"
                style={{ color: primaryColor }}
              >
                {attribute?.attribute?.answers?.[1]?.label}
              </span>
            </div>
          </div>
        </div>
      </div>
    )

  if (attribute?.attribute?.widgetType === 4)
    return (
      <div className=" col-5 ">
        <div className="top-effectiveness-wrapper">
          <div className="top-effectiveness">
            <div className="rating-bar-wrapper">
              <span className="effectiveness-text">
                {attribute?.attribute?.question}
              </span>
              <RatingBarBoxes
                rating={
                  (attribute?.totalEfc / attribute?.totalCount).toFixed(0) / 20
                }
              />
            </div>
            <div className="worked d-flex justify-content-between ">
              <span className="effectiveness-text">
                {attribute?.attribute?.answers?.[0]?.label}
              </span>
              <span className="effectiveness-text">
                {attribute?.attribute?.answers?.[1]?.label}
              </span>
              <span className="effectiveness-text">
                {attribute?.attribute?.answers?.[2]?.label}
              </span>
            </div>
          </div>
        </div>
      </div>
    )

  if (attribute?.attribute?.widgetType === 5)
    return (
      <div className=" col-5 ">
        <div className="top-effectiveness-wrapper">
          <div className="top-effectiveness">
            <div className="rating-bar-wrapper">
              <span className="effectiveness-text">
                {attribute?.attribute?.question}
              </span>
              <RatingBarBoxes
                rating={
                  (attribute?.totalEfc / attribute?.totalCount).toFixed(0) / 20
                }
              />
            </div>
            <div className="worked d-flex justify-content-between ">
              <span className="effectiveness-text">
                {attribute?.attribute?.answers?.[0]?.label}
              </span>
              <span className="effectiveness-text">
                {attribute?.attribute?.answers?.[1]?.label}
              </span>
              <span className="effectiveness-text">
                {attribute?.attribute?.answers?.[2]?.label}
              </span>
            </div>
          </div>
        </div>
      </div>
    )
  else return <div></div>
}

export const checkAccess = (access, widget, returnWidget) => {
  const apiManager = ApiManager.getInstance()
  let sub = apiManager._getSubscription()
  if (sub === null) return widget
  if (sub?.[access] === true) return widget
  return returnWidget ?? <div></div>
}

export function getCookie(key) {
  var b = document.cookie.match("(^|;)\\s*" + key + "\\s*=\\s*([^;]+)")
  return b ? b.pop() : ""
}

export const getFirstNameAndLastInitial = (fullName) => {
  // Split the full name into an array of words
  const nameArray = fullName?.trim()?.split(" ")
  if (!nameArray) return ""
  // Get the first name (first word)
  const firstName = nameArray[0]
  // const lastName = nameArray[0]
  // Get the last name (last word) and extract its first letter
  const lastName = nameArray.length > 1 ? nameArray[nameArray.length - 1] : ""
  const lastInitial = lastName.charAt(0).toUpperCase()
  const firstInitial = firstName.charAt(0).toUpperCase()
  let apiManager = ApiManager.getInstance()
  let data = "firsInitialLastInitial"
  console.log("firsInitialLastInitial", apiManager._getGeneralSettings())
  data = apiManager._getGeneralSettings()
  console.log("firsInitialLastInitial111111", data)
  if (data === "firstNameLastInitial") {
    return `${firstName} ${lastInitial}.`
  } else if (data === "firstName") {
    return `${firstName}`
  } else if (data === "lastName") {
    return `${lastName}`
  } else if (data === "firsInitialLastInitial") {
    return `${firstInitial} ${lastInitial}.`
  } else if (data === "firstNameLastName") {
    return `${firstName} ${lastName}`
  } else return `${firstName} ${lastInitial}.`
}

export const getInitial = (fullName) => {
  // Split the full name into an array of words
  const nameArray = fullName?.trim()?.split(" ")
  if (!nameArray) return ""
  // Get the first name (first word)
  const firstName = nameArray[0]

  // Get the last name (last word) and extract its first letter
  const lastName = nameArray[nameArray.length - 1]
  console.log("last name==>", lastName)
  const lastInitial = lastName.charAt(0).toUpperCase()
  console.log("last initial==>", lastInitial)

  const firstInitial = firstName.charAt(0).toUpperCase()
  console.log("last first==>", firstInitial)

  let apiManager = ApiManager.getInstance()
  let data = "firsInitialLastInitial"
  // console.log("firsInitialLastInitial", apiManager._getGeneralSettings())
  // data = apiManager._getGeneralSettings()
  console.log("firsInitialLastInitial111111", data)
  // if (data === "firstNameLastInitial") {
  //   return `${firstName} ${lastInitial}.`
  // } else if (data === "firstName") {
  //   return `${firstName}`
  // } else if (data === "lastName") {
  //   return `${lastName}`
  // } else if (data === "firsInitialLastInitial") {
  return `${firstInitial}${lastInitial}`
  // } else return `${firstName} ${lastInitial}.`
}

export const AttributesComponent = ({
  attributes,
  secondaryColor,
  primaryColor,
}) => {
  const mappedWidgetTypes = []

  const attributesContent = attributes?.reduce((accumulator, attribute) => {
    if (
      attribute?.widgetType === 6 ||
      attribute?.widgetType === 7 ||
      attribute?.widgetType === 8
    ) {
      const widgetType = attribute?.question

      if (mappedWidgetTypes.includes(widgetType)) {
        const existingContent = accumulator.find(
          (item) => item.widgetType === widgetType
        )

        // Update the content by adding a new element
        existingContent.answer.push(attribute?.selectedAnswer.value)
        existingContent.content = (
          <span key={widgetType} style={{ color: secondaryColor }}>
            <strong style={{ color: primaryColor }}>
              {attribute?.question}:{" "}
            </strong>
            {existingContent?.answer.map((item) => (
              <span className="multiple-items"> {item} </span>
            ))}
          </span>
        )
      } else {
        mappedWidgetTypes.push(widgetType)

        // Initialize content as an array with the first element
        accumulator.push({
          widgetType,
          answer: [attribute?.selectedAnswer.value],
          content: [
            <span key={widgetType} style={{ color: primaryColor }}>
              <span style={{ fontWeight: 500, color: primaryColor }}>
                {attribute?.question}:{" "}
              </span>
              {attribute?.selectedAnswer.value}
            </span>,
          ],
        })
      }
    }

    return accumulator // Make sure to return the accumulator after each iteration
  }, []) // Initialize accumulator as an empty array

  // Flatten the content array before rendering
  const flattenedContent = attributesContent?.flatMap((item) => item?.content)
  console.log("flatcontent", flattenedContent)
  return <>{flattenedContent}</>
}
function getFinalPath(imageUrl) {
  const urlParts = imageUrl.split("/")
  return urlParts.slice(3).join("/")
}

export function getImageUrl(image) {
  // return image;
  if (image.includes(S3_URL)) {
    let path = getFinalPath(image)
    return CF_URL + path
  }
  if (
    image.includes(OLD_S3_URL) ||
    image.includes(OLD_S3_URL2) ||
    image.includes("me.judge.review-images")
  )
    return image
  return CF_URL + image
}

export function getThumbnailImageUrl(image) {
  // return image;
  if (image.includes(S3_URL)) {
    let path = getFinalPath(image)
    return CF_URL + THUMBNAIL_URL + path
  }
  if (image.includes(OLD_S3_URL)) {
    let path = getFinalPath(image)
    return OLD_S3_URL + THUMBNAIL_URL + path
  }
  if (image.includes(OLD_S3_URL2)) {
    let path = getFinalPath(image)
    return OLD_S3_URL2 + THUMBNAIL_URL + path
  }
  if (image.includes("me.judge.review-images")) return image
  return CF_URL + THUMBNAIL_URL + image
}
