import "swiper/css"
import "swiper/css/navigation"
import { Autoplay, Navigation } from "swiper"
import { SwiperSlide, Swiper } from "swiper/react"
import imgFour from "../../../../asst/slider-img-9.png"
import "../../../Sliders/sliders.scss"
import { getThumbnailImageUrl } from "../../../../helper"

const EGCSlider = ({ reviews }) => {
  return (
    <div className="sliders">
      <div className="slider-wrapper-5">
        <>
          <Swiper
            navigation={true}
            slidesPerView={5}
            spaceBetween={42}
            // initialSlide={"3"}
            autoplay={{ delay: 3000 }}
            loop={true}
            // centeredSlides={true}
            modules={[Navigation, Autoplay]}
            breakpoints={{
              300: {
                slidesPerView: 1.3,
                spaceBetween: 24,
                centeredSlides: false,
                autoplay: true,
                // initialSlide: "3",
              },
              375: {
                slidesPerView: 3,
                spaceBetween: 6,
                centeredSlides: false,
                autoplay: true,
                // initialSlide: "3",
              },

              550: {
                slidesPerView: 3,
                spaceBetween: 24,
                centeredSlides: false,
                autoplay: true,
                // initialSlide: "3"
              },

              768: {
                slidesPerView: 3.5,
                spaceBetween: 24,
                autoplay: true,
                centeredSlides: false,
              },
              1000: {
                slidesPerView: 4,
                spaceBetween: 24,
                centeredSlides: false,
              },

              1200: {
                slidesPerView: 4.8,
                // initialSlide: 3,
                spaceBetween: 10,
                // centeredSlides: true,
              },
              1440: {
                slidesPerView: 5,
                // initialSlide: 3,
                spaceBetween: 10,
                // centeredSlides: true,
              },
              1920: {
                slidesPerView: 5,
                // initialSlide: 3,
                spaceBetween: 10,
                // centeredSlides: true,
              },
            }}
            className="slider_5"
          >
            {reviews?.docs?.map((item, index) => (
              <SwiperSlide key={index}>
                <div
                  class="slide-content c-pointer video-img"
                  onClick={() => {
                    setTimeout(
                      () =>
                        window.parent.postMessage({ reviewId: item._id }, "*"),
                      200
                    )
                  }}
                >
                  {item?.images && item?.images[0]?.mediaType === 2 ? (
                    <img
                      src={getThumbnailImageUrl(item?.images[0]?.mediaUrl)}
                      class="happy-customer-img"
                      alt="product"
                    />
                  ) : (
                    <>
                      <video
                        src={item?.images ? item?.images[0]?.mediaUrl : ""}
                        class="happy-customer-img"
                        alt="product"
                      />
                      <button className="video-btn">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="29"
                          height="30"
                          viewBox="0 0 29 30"
                          fill="none"
                        >
                          <circle
                            cx="14.5397"
                            cy="14.3956"
                            r="13.9264"
                            fill="white"
                            fill-opacity="0.7"
                          />
                          <path
                            d="M11.2656 21.4478L16.3807 18.1825V11.6518L11.2656 8.38647V21.4478ZM16.3807 18.1825L21.4958 14.9172L16.3807 11.6518V18.1825Z"
                            fill="#050505"
                          />
                        </svg>
                      </button>
                    </>
                  )}
                  <div class="bottom-section">
                    {/* <div class="happy-customer-stars">
                      {Array(item?.reviewRating)
                        .fill()
                        .map((_, i) => (
                          <div class="star" key={i}>
                            <svg
                              width="17"
                              height="16"
                              viewBox="0 0 17 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M8.51141 0.635986L10.5567 6.10575L16.3908 6.36068L11.8207 9.9961L13.3811 15.6234L8.51141 12.4005L3.6417 15.6234L5.20208 9.9961L0.632052 6.36068L6.46613 6.10575L8.51141 0.635986Z"
                                fill="white"
                              />
                            </svg>
                          </div>
                        ))}

                    </div>

                    <p class="description pb-2" style={{ color: "white" }} >{item?.reviewDescription?.substring(0, 40) + "..."}</p>
                    <a href="#" style={{ color: "white" }} class="redirect-link"

                    >
                      Read more
                    </a> */}
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </>
      </div>
    </div>
  )
}

export default EGCSlider
