import React, { useRef, useState } from "react"
import { Button } from "reactstrap"
import { useSwiper } from "swiper/react"
import QuoliLogo from "../../../asst/quoli-logo.svg"
import { OfferDiscountCode } from "../../../Svgs/Svg"
import PoweredByQuoli from "../PoweredByQuoli"

export default function OfferDiscount(props) {
  let { settings, onReviewSelect, data, setOrderProductIndex, discount } = props
  const swiper = useSwiper()
  console.log("hellonuman", settings)
  const [copySuccess, setCopySuccess] = useState(false)

  const textAreaRef = useRef(null)

  function copyToClipboard(e) {
    textAreaRef.current.select()
    document.execCommand("copy")
    // This is just personal preference.
    // I prefer to not show the whole text area selected.
    e.target.focus()
    setCopySuccess(true)

    setTimeout(() => {
      setCopySuccess(false)
    }, 2000)
  }

  const storereviewurl = `QU-HDNS10`

  return (
    <div
      className="review-simple-container review-rcf-sub"
      onLoad={() => {
        setTimeout(() => {
          window.parent.postMessage({ showRcf: true }, "*")
          window.location.reload()
        }, 3000)
      }}
    >
      <div className="review-sub-simple-popup">
        <div className="popup-content my-submit-popup">
          <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
            <h3 className="highlight review-text-heading">Congratulations</h3>
            <p className="review-simple-highlight review-text-submit">
              You've unlocked a winning discount: Get 10% off your next purchase
              with code
            </p>
          </div>
          <div className="d-flex justify-content-center">
            <>
              {/* Hidden textarea for copying the text */}
              <textarea
                ref={textAreaRef}
                value={storereviewurl}
                style={{ position: "absolute", left: "-9999px" }}
                readOnly
              />

              {/* Display the button if the copy command is supported */}
              {document.queryCommandSupported("copy") && (
                <div className="rcf-dicountcode" onClick={copyToClipboard}>
                  {copySuccess ? "Copied" : discount?.code}{" "}
                  <OfferDiscountCode />
                </div>
              )}
            </>

            <div className="QR-main-div qr-div-textarea-link border-class">
              <div className="d-flex flex-column gap-3 align-items-center qr-textfield-w">
                <div className="qr-w-set">
                  {/* <input
                    ref={textAreaRef}
                    value={storereviewurl}
                    className="qr-link-copy-view"
                  /> */}
                </div>
              </div>
            </div>
          </div>
          <div className=" d-flex justify-content-center">
            <Button
              className="rcf-submit-btn-sub"
              style={{
                maxWidth: "240px",
                display: "flex",
                justifyContent: "center",
              }}
              onClick={() => {
                setOrderProductIndex(1)
                window.parent.postMessage({ showRcf: true }, "*")

                window.location.reload()
              }}
            >
              Redeem Code
            </Button>
          </div>
          <div className="text-center w-100">
            <PoweredByQuoli />
          </div>
          {/* <div className="last-submit-heading-main-container">
            <span className="rcf-company-heading-powered">Powered by</span>
            <div className="quoli-logo-main-div">
              <img src={QuoliLogo} alt="QuoliLogo" />
              <span className="quoli-title-text-tag">Quoli</span>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  )
}
